$(function () {

  $('#keycontent').each(function () {
    // objects
    var $kikyu = $('.kikyu');
    var $cloud01 = $('.cloud1');
    var $cloud02 = $('.cloud2');
    var $cloud03 = $('.cloud3');
    var $cloud04 = $('.cloud4');
    var $cloud05 = $('.cloud5');
    var $cloud06 = $('.cloud6');
    var $cloud07 = $('.cloud7');
    var $cloud08 = $('.cloud8');
    var $cloud09 = $('.cloud9');
    var $cloud10 = $('.cloud10');

    var $shin = $(".shinkansen");
    var $man1 = $('.man1');
    var $man2 = $('.man2');
    var $bird1 = $('.bird1');
    var $bird2 = $('.bird2');
    var $bicycle1 = $(".bicycle1");
    var $bicycle2 = $(".bicycle2");

    var $plane = $(".plane");
    var $bike1 = $(".bike1");
    var $bike2 = $(".bike2");
    var $track = $(".track1");
    var $car   = $(".car1");

    var {
      cloud1,
      cloud2,
      cloud3,
      cloud4,
      cloud5,
      cloud6,
      cloud7,
      cloud8,
      cloud9,
      cloud10,
      shinkansen,
      plane,
      kikyu,
      track1,
      car1,
      bike1,
      bike2,
      bird1,
      bird2,
      bicycle1,
      bicycle2,
      man1,
      man2,
    } = window.an;


    // set parts
    $track.frameAnimation(track1.parts);
    $car.frameAnimation(car1.parts);
    $bike1.frameAnimation(bike1.parts);
    $bike2.frameAnimation(bike2.parts);
    $bird1.frameAnimation(bird1.parts);
    $bird2.frameAnimation(bird2.parts);
    $bicycle1.frameAnimation(bicycle1.parts);
    $bicycle2.frameAnimation(bicycle2.parts);
    $man1.frameAnimation(man1.parts);
    $man2.frameAnimation(man2.parts);

    // animation set up
    cloud1.animate = function() {
      $cloud01.animate({
        marginTop: '-=10px'
      }, cloud1.el.speed1).animate({
        marginTop: '+=10px'
      }, cloud1.el.speed2, cloud1.animate);
    };
    
    cloud2.animate = function() {
      $cloud02.delay(cloud2.el.delay1).animate({
        marginTop: '+=10px',
        marginLeft: '+=10px'
      }, cloud2.el.speed1).animate({
        marginTop: '-=10px',
        marginLeft: '-=10px'
      }, cloud2.el.speed2, cloud2.animate);
    };

    cloud3.animate = function() {
      $cloud03.delay(cloud3.el.delay1).animate({
        marginTop: '+=5px',
        marginLeft: '-=5px'
      }, cloud3.el.speed1).animate({
        marginTop: '-=5px',
        marginLeft: '+=5px'
      }, cloud3.el.speed2, cloud3.animate);
    };

    cloud4.animate = function() {
      $cloud04.animate({
        marginTop: '+=10px'
      }, cloud4.el.speed1).animate({
        marginTop: '-=10px'
      }, cloud4.el.speed2, cloud4.animate);
    };

    cloud5.animate = function() {
      $cloud05.delay(cloud5.el.delay1).animate({
        marginTop: '+=10px',
        marginLeft: '+=10px'
      }, cloud5.el.speed1).animate({
        marginTop: '-=10px',
        marginLeft: '-=10px'
      }, cloud5.el.speed2, cloud5.animate);
    };

    cloud6.animate = function() {
      $cloud06.animate({
        marginTop: '-=10px'
      }, cloud6.el.speed1).animate({
        marginTop: '+=10px'
      }, cloud6.el.speed2, cloud6.animate);
    };

    cloud7.animate = function() {
      $cloud07.animate({
        marginTop: '+=10px'
      }, cloud7.el.speed1).animate({
        marginTop: '-=10px'
      }, cloud7.el.speed2, cloud7.animate);
    };

    cloud8.animate = function() {
      $cloud08.animate({
        marginTop: '+=10px'
      }, cloud8.el.speed1).animate({
        marginTop: '-=10px'
      }, cloud8.el.speed2, cloud8.animate);
    };

    cloud9.animate = function() {
      $cloud09.animate({
        marginTop: '+=10px'
      }, cloud9.el.speed1).animate({
        marginTop: '-=10px'
      }, cloud9.el.speed2, cloud9.animate);
    };

    cloud10.animate = function() {
      $cloud10.animate({
        marginTop: '+=10px'
      }, cloud10.el.speed1).animate({
        marginTop: '-=10px'
      }, cloud10.el.speed2, cloud10.animate);
    };

    shinkansen.animate = function() {
      $shin.delay(shinkansen.el.delay1).animate({
        left: "100%"
      }, shinkansen.el.speed1, "linear").delay(shinkansen.el.delay2).animate({
        left: -508
      }, shinkansen.el.speed2, "linear", shinkansen)
    };

    plane.animate = function() {
      $plane.animate({
        left: "100%",
        top: 50
      }, plane.el.speed1, "linear").delay(plane.el.speed2).animate({
        left: -243,
        top: 100
      }, 0, plane.animate);
    };

    kikyu.animate = function() {
      $kikyu.delay(kikyu.el.delay1).animate({
        left: -120,
        top: 50
      }, kikyu.el.speed1, "linear").delay(kikyu.el.delay2).animate({
        left: "100%",
        top: 150
      }, kikyu.el.speed2, kikyu.animate);
    };

    track1.animate = function() {
      $track.animate({
        left: "100%"
      }, track1.el.speed1, "linear").delay(track1.el.delay1).animate({
        left: -200
      }, track1.el.speed2, track1.animate);
    };

    car1.animate = function() {
      $car.animate({
        left: -160
      }, car1.el.speed1, "linear").delay(car1.el.delay1).animate({
        left: "100%"
      }, car1.el.speed2, car1.animate);
    };

    bike1.animate = function() {
      $bike1.animate({
        left: "100%"
      }, bike1.el.speed1, "linear").delay(bike1.el.delay1).animate({
        left: -165
      }, bike1.el.speed2, bike1.animate)
    };

    bike2.animate = function() {
      $bike2.animate({
        left: -275
      }, bike2.el.speed1, "linear").delay(bike2.el.delay1).animate({
        left: "100%"
      }, bike2.el.speed2, bike2.animate)
    };

    bird1.animate = function() {
      $bird1.animate({
        left: "100%",
        top: 50
      }, bird1.el.speed1, "linear").delay(bird1.el.delay1).animate({
        left: -81,
        top: 220
      }, bird1.el.speed2, bird1.animate)
    };

    bird2.animate = function () {
      $bird2.delay(bird2.el.delay1).animate({
        left: -70,
        top: 50
      }, bird2.el.speed1, "linear").delay(bird2.el.delay1).animate({
        left: "100%",
        top: 220
      }, bird2.el.speed2, bird2.animate);
    }

    bicycle1.animate = function() {
      $bicycle1.animate({
        left: "100%"
      }, bicycle1.el.speed1, "linear").delay(bicycle1.el.delay1).animate({
        left: -100
      }, bicycle1.el.speed2, bicycle1.animate)
    }

    bicycle2.animate = function() {
      $bicycle2.animate({
        left: -112
      }, bicycle2.el.speed1 , "linear").delay(bicycle2.el.delay1).animate({
        left: "100%"
      }, bicycle2.el.speed2, bicycle2.animate);
    };

    man1.animate = function() {
      $man1.delay(man1.el.delay1).animate({
        left: -70
      }, man1.el.speed1, "linear").delay(man1.el.delay2).animate({
        left: "100%"
      }, man1.el.speed2, man1.animate);
    }

    man2.animate = function() {
      //$(".man2").animate({
      //  left: -70
      //}, 60000, "linear").animate({
      //  left: "100%"
      //}, 0, man2)
    }
    
    $.each(an, function(key, obj) {
      var target = an[key];
      if (target.animate) {
        target.animate();
      }
    });

  });

});

